/* Base styles for the slider */
.slider {
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    width: 572px; /* Slider width */
    height: 8px; /* Track height */
    background: #FFFFFF; /* Track color */
    outline: none; /* Remove outline */
    opacity: 0.7; /* Track opacity on normal state */
    transition: opacity .2s; /* Smooth transition for hover effect */
    border-radius: 20px; /* Track border radius */
  }
  
  /* Styles for the slider thumb */
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Remove default style */
    appearance: none;
    border-radius: 20px;
    width: 5px; /* Width of the thumb */
    height: 15px; /* Height of the thumb */
    background: #FFFFFF; /* Thumb color */
    cursor: pointer; /* Cursor on hover */
    box-shadow: 0 0 3px #333333; /* Shadow for the thumb */
  }

  
  
  
  
  /* Styles for the slider when active or in focus */
  
  .slider:active::-moz-range-thumb,
  .slider:focus::-moz-range-thumb {
    background: #cccccc; /* Change thumb color when active */
  }
  